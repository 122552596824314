import { Box, Card, CardContent, CardHeader, Divider, Grid, Stack, styled } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Icon, IconProps } from "../../atoms/Icon/Icon";


const CardWrapper = styled(Box)(
  () => `
        width: 100%;
        display: flex;
      `
);

interface StyledCardProps {
  children: JSX.Element | React.ReactNode;
  title?: string;
  icon?: IconProps['icon'];
  cardActions?: JSX.Element;
}

export const StyledCard = ({ children, title, icon, cardActions }: StyledCardProps) => {
  return (
    <Card
      sx={{
        minWidth: 500,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        padding: '0%',
        justifyContent: 'center',
        backgroundColor: 'white',
        width: '100%'
      }}
    >
      {title && (
        <>
          <CardHeader
            titleTypographyProps={{
              variant: 'bodySmall',
            }}
            title={title}
            avatar={icon ? <Icon icon={icon} /> : null}
            action={cardActions}
          />
          <Divider />
        </>
      )}
      <CardContent
        sx={{
          px: 0,
        }}
      >
        {children || <Outlet />}
      </CardContent>
    </Card>
  );
};

export interface Content {
  [key: string]: string;
};

export interface InfoCardProps {
  title: string;
  content: Content[];
  expandedContent?: Content[];
  deleteClick?: () => void;
  editClick?: () => void;
};

export const InfoCardWithExpand = (props: InfoCardProps) => {
  const { content, title } = props;
  // const theme = useTheme();

  // const [expandOpen, setExpandOpen] = useState(false);

  const contentProperties = Object.keys(content);
  // const expandedProperties = Object.keys(expandedContent ?? {});

  return (
    <CardWrapper>
      <StyledCard
        title={title}
      >
        <Stack spacing={1} width="100%" alignItems={'center'} direction={"column"}>
          <Grid container spacing={2}>
            {content.map((content, index) => {
              return (
                <Grid item xs={4}>
                  {`${contentProperties[index]}: ${content}`}
                </Grid>
              )
            })}
          </Grid>
        </Stack>
      </StyledCard>
    </CardWrapper>
  )
};
