import {
	Box,
	Typography,
	styled,
	useTheme,
	BoxProps,
	Stack,
	Button,
} from '@mui/material';

import { useDropzone } from 'react-dropzone';
import { Icon } from '../../atoms/Icon/Icon';

interface ContainerProps extends BoxProps {
	displayFile?: boolean;
	isDragReject?: boolean;
}
const Container = styled(Box)<ContainerProps>(({ theme, isDragReject }) => ({
	border: '2px  dashed',
	borderColor: isDragReject ? theme.colors.error.main : 'rgb(140, 140, 140)',
	borderRadius: '4px',
	padding: theme.spacing(2),
	textAlign: 'center',
	marginTop: '20px',
	marginBottom: '10px',
	transition: 'background-color 0.3s',
	width: '80%',
	cursor: 'pointer',
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
	backgroundColor: theme.colors.alpha.white[100],
	'&:hover': {
		backgroundColor: theme.colors.primary.lighter,
	},
}));

import { useMemo, useCallback } from 'react';

// Props interface for the UploadDropZone component
export interface IUploadDropZoneProps {
	updateFiles: (files: File[]) => void;
	maxFiles?: number;
	title?: string;
}

// Style for when the dropzone is focused
const focusedStyle = {
	borderColor: '#4DA02B',
};

// Style for when a file being dragged is rejected
const acceptStyle = {
	borderColor: '#4DA02B',
};

// The UploadDropZone component
const rejectStyle = {
	borderColor: '#ff1744',
};

// The UploadDropZone component
export function UploadDropZone(props: IUploadDropZoneProps) {
	const { updateFiles, maxFiles, title } = props;
	const onDropAccepted = useCallback(
		(acceptedFiles: any) => {
			updateFiles(acceptedFiles);
		},
		[props]
	);

	const theme = useTheme();

	const {
		getRootProps,
		getInputProps,
		isFocused,
		isDragAccept,
		isDragActive,
		isDragReject,
		fileRejections,
	} = useDropzone({
		accept: {
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
			'application/vnd.ms-excel': [],
			'application/pdf': [],
		},
		onDropAccepted,
		multiple: !!maxFiles,
		maxFiles: maxFiles ?? 1,
	});
	// Determine the current style for the dropzone based on its focus and the status of files being dragged
	const style = useMemo(
		() => ({
			...(isFocused || isDragActive ? focusedStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isFocused, isDragAccept, isDragReject, isDragActive]
	);

	const errorMessageInvalidType =
		'Upload of file failed, only .pdf, .png, .jpeg, .jpg and .gif file extensions are allowed.';

	// Render the dropzone
	return (
		<Box
			display="flex"
			flexDirection="row"
			flexGrow={1}
			justifyContent={'center'}
		>
			<Container
				className={isDragActive ? 'active' : ''}
				{...getRootProps({ style })}
				as={'div'}
				sx={{ width: '100%', borderColor: theme.colors.alpha.grey[2] }}
			>
				<input {...getInputProps()} />
				<Stack gap={1} justifyContent={'center'} alignItems={'center'}>
					<Icon icon="UilCloudUpload" fontSize="large" />
					<Stack>
						<Typography variant="body1" fontWeight={'bold'} fontSize={'16px'}>
							Drag & Drop File
						</Typography>
						{!isDragReject && (
							<>
								<Typography
									variant="body1"
									fontWeight={'bold'}
									fontSize={'14px'}
								>
									or
								</Typography>
								<Button variant={'outlined'} color="secondary" size="small">
									Browse...
								</Button>
							</>
						)}
						{isDragReject && (
							<Typography
								variant="body1"
								fontSize={'12px'}
								color={theme.colors.error.main}
							>
								{isDragReject && errorMessageInvalidType}
							</Typography>
						)}
					</Stack>
				</Stack>
			</Container>
		</Box>
	);
}
