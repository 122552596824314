import * as Sentry from '@sentry/react';
import { clarity } from 'react-microsoft-clarity';

export interface SentryConfig {
  dsn?: string;
  normalizeDepth: number;
  integrations: any[];
  // tracePropagationTargets: string[];
  environment?: string;
  tracesSampleRate: number;
  replaysSessionSampleRate: number;
  replaysOnErrorSampleRate: number;
}

export const initMonitoring = (sentryConfig: SentryConfig, clarityID: string): void => {
  if (process.env.REACT_APP_DEPLOYMENT_ENV === 'local' || process.env.REACT_APP_DEPLOYMENT_ENV === 'development') {
    console.log('not adding sentry since in development');
    console.log('not adding clarity since in development');
  } else {
    Sentry.init(sentryConfig);
    clarity.init(clarityID); // can capture extra metrics by looking at https://www.npmjs.com/package/react-microsoft-clarity
  }
};
