import { styled, Box, ModalProps, Modal, SxProps, Theme } from '@mui/material';

const ModalWrapper = styled(Box)(
	({ theme }) => `
            display: flex;
            flex-direction: column;
            gap: 24px;
            background-color: ${theme.colors.alpha.white[100]};
            border-radius: 30px;
            padding: 40px;
            justify-content: center;
            align-items: center;
            width: auto;
        `
);

interface CustomModalProps extends ModalProps {
	wrapperWidth?: string;
	wrapperStyle?: SxProps<Theme>;
}
const CustomModal = ({
	open,
	onClose,
	wrapperWidth = 'auto',
	wrapperStyle,
	...props
}: CustomModalProps) => (
	<Modal
		open={open}
		onClose={onClose}
		sx={{
			'& .MuiModal-root': {
				overflow: 'scroll',
			},
		}}
		{...props}
	>
		<ModalWrapper sx={{ width: wrapperWidth, ...wrapperStyle }}>
			{props.children}
		</ModalWrapper>
	</Modal>
);

export { CustomModal };
