import {
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps
} from '@mui/material';

type FormControlLabelBaseProps = Pick<
  MuiFormControlLabelProps,
  'labelPlacement' | 'value' | 'label' | 'sx' 
>;

interface CheckboxProps extends MuiCheckboxProps, FormControlLabelBaseProps {
  color?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | 'default';
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}
export const Checkbox = ({ label, labelPlacement,sx, ...rest }: CheckboxProps) => {
  if (!label) {
    return <MuiCheckbox {...rest} />;
  } else
    return (
      <MuiFormControlLabel
        control={<MuiCheckbox {...rest} />}
        label={label}
        labelPlacement={labelPlacement}
        sx={sx}
      />
    );
};
