import { Box, Popover, styled } from '@mui/material';

export const NotificationPopoverWrapper = styled(Popover)(
  `
        .MuiPopover-paper {
            border-radius: 15px;
            width: 443px;
            `
);

export const NoNotificationsWrapper = styled(Box)(
  ({ theme }) => `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: ${theme.spacing(2, 0)};
        padding: 7px 0px 10px;
        gap: 20px;
        `
);

export const DashedImageBox = styled(Box)(
  ({ theme }) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 12px;
    border: 1px dashed ${theme.colors.alpha.grey[3]};
    border-radius: 15px;
   
    `
);

export const QuestionFormWrapper = styled(Box)(
  `
        display: flex;
        flex-direction: column;
        width: 613px
        `
);
