import {
  Box,
  Card,
  Stack,
  Typography,
  useTheme,
  Chip,
  styled
} from '@mui/material';
import { toast } from 'react-toastify';
import { Button } from 'features';

const CardWrapper = styled(Box)(
  () => `           
      width: 100%;
      display: flex;
    `
);

const StyledButton = styled(Button)(({ theme }) => ({
  padding: 0,
  fontFamily: 'Noto Sans',
  fontWeight: 400,
  fontSize: 14,
  letterSpacing: '0.01em',
  lineHeight: '130%',
  backgroundColor: 'transparent',
  color: theme.colors.alpha.white[100],
  '&.MuiButton-root': {
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: 'transparent'
    }
  }
}));

interface AgentInfoProps {
  /** Username */
  name: string;
  /** User email */
  email: string;
  /** User phone */
  phone: string;
}

export const AgentInfo = ({ name, email, phone }: AgentInfoProps) => {
  const theme = useTheme();

  const handleClick = (value: string) => {
    navigator.clipboard.writeText(value);
    toast.success('Copied to clipboard');
  };

  return (
    <CardWrapper>
      <Card
        sx={{
          width: '100%',
          padding: '7% 5% 7% 5%',
          backgroundColor: theme.colors.primary.dark
        }}
      >
        <Stack spacing={1} width="100%" alignItems={'center'}>
          <Chip label="Crop Advisor" color="primary" size='small' sx={{width:'100%'}} />
          <Typography
            variant="bodyBold"
            align="center"
            sx={{ color: theme.colors.alpha.white[100] }}
          >
            {name}
          </Typography>
          <Stack spacing={0.5} alignItems={'flex-start'}>
            <StyledButton startIcon="UilEnvelope" onClick={() => handleClick(email)}>{email}</StyledButton>
            {phone !== "" && <StyledButton startIcon="UilPhoneAlt" onClick={() => handleClick(phone)}>{phone}</StyledButton>}
          </Stack>
        </Stack>
      </Card>
    </CardWrapper>
  );
};
