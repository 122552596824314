import { useState } from 'react';
import { IconProps } from 'features';

export const useTogglePasswordVisibility = () => {
  const [passwordVisibility, setPasswordVisibility] = useState<boolean>(false);
  const [eyeIcon, setEyeIcon] = useState<IconProps['icon']>('UilEyeSlash');
  const [tooltip, setTooltip] = useState<string>('Show Password');
  const handlePasswordVisibility = () => {
    if (eyeIcon === 'UilEye') {
      setEyeIcon('UilEyeSlash');
      setPasswordVisibility(!passwordVisibility);
      setTooltip('Show Password');
    } else if (eyeIcon === 'UilEyeSlash') {
      setEyeIcon('UilEye');
      setPasswordVisibility(!passwordVisibility);
      setTooltip('Hide Password');
    }
  };

  return {
    passwordVisibility,
    eyeIcon,
    handlePasswordVisibility,
    tooltip,
  };
};

