import { Box, styled } from '@mui/material';

export const ModalWrapper = styled(Box)(
	({ theme }) => `
          display: flex;
          flex-direction: column;
          gap: 24px;
          background-color: ${theme.colors.alpha.white[100]};
          border-radius: 30px;
          padding: 40px;
          justify-content: center;
          align-items: center;
          width: 40%;
      `
);
