import {
	Breadcrumbs as MuiBreadcrumbs,
	BreadcrumbsProps,
	Chip,
	styled,
	useTheme,
	Box,
} from '@mui/material';
import { Icon, IconProps } from 'features';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledChip = styled(Chip)(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	backgroundColor: theme.colors.primary.lighter,
	'& .MuiChip-label': {
		color: theme.colors.primary.main,
	},
})) as typeof Chip;

const getIcon = (name: string): IconProps['icon'] => {
	switch (name.toLowerCase()) {
		case 'account':
			return 'UilEstate';
		case 'my farm':
			return 'UilUser';
		case 'products':
			return 'UilBox';
		case 'users':
			return 'UilUsersAlt';
		default:
			return 'UilEstate';
	}
};

export const Breadcrumbs = ({ ...props }: BreadcrumbsProps) => {
	const theme = useTheme();
	const location = useLocation();
	const navigate = useNavigate();

	const pathnames = location.pathname.split('/').filter((x) => x);

	return (
		<MuiBreadcrumbs {...props}>
			{pathnames.map((value, index) => {
				const first = index === 0;
				const last = index === pathnames.length - 1;
				const to = `/${pathnames.slice(0, index + 1).join('/')}`;
				// replace dashes with spaces, then capitalize all words
				const displayName = value
					.replace(/-/g, ' ')
					.replace(/:/g, ' ')
					.replace(/%20/gm, ' ')
					.replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase());

				if (first) {
					return (
						<Box
							key=""
							display="flex"
							justifyContent="center"
							alignItems="center"
						>
							<Icon
								key={`first-breadcrumb-icon-${index}`}
								icon={getIcon(displayName)}
								sx={{ color: theme.colors.alpha.black[100], mr: 1.5 }}
							/>
							<Icon
								icon="UilAngleRight"
								sx={{ color: theme.colors.alpha.black[100] }}
							/>
							<StyledChip
								key={`first-breadcrumb-chip-${index}`}
								label={displayName}
								sx={{ cursor: 'pointer' }}
								clickable={false}
								onClick={() => navigate(to, { state: location.state })}
							/>
						</Box>
					);
				} else if (last) {
					return (
						<StyledChip
							key={`last-breadcrumb-chip-${index}`}
							label={displayName}
						/>
					);
				} else {
					return (
						<StyledChip
							key={`breadcrumb-chip-${index}`}
							label={displayName}
							sx={{ cursor: 'pointer' }}
							clickable={false}
							onClick={() => navigate(to, { state: location.state })}
						/>
					);
				}
			})}
		</MuiBreadcrumbs>
	);
};
