import {
	IconButton as MuiIconButton,
	IconButtonProps as MuiIconButtonProps,
} from '@mui/material';
import { Icon, IconProps } from 'features';
import { forwardRef } from 'react';

export interface IconButtonProps extends MuiIconButtonProps {
	icon: IconProps['icon'] | IconProps['icon'][];
	first?: boolean;
}

const IconButtonRef = (props: IconButtonProps) => {
	return (
		<MuiIconButton {...props}>
			{props.first && props.children}
			{Array.isArray(props.icon) ? (
				props.icon.map((icon, index) => <Icon key={index} icon={icon} />)
			) : (
				<Icon icon={props.icon} />
			)}
			{!props.first && props.children}
		</MuiIconButton>
	);
};

export const IconButton = forwardRef((props: IconButtonProps, ref) => (
	<IconButtonRef {...props} />
));
