import { Suspense, lazy, FC } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';
import { SuspenseLoader } from 'features';
import pages from './routes';
import { AuthLayout } from 'src/components/organisms/AuthLayout/AuthLayout';

const Loader = (Component: FC) => (props: any) =>
	(
		<Suspense fallback={<SuspenseLoader />}>
			<Component {...props} />
		</Suspense>
	);

// Pages
const Login = Loader(lazy(() => import('src/components/pages/Login/Login')));
const Register = Loader(lazy(() => import('src/components/pages/Register')));
const ForgotPassword = Loader(
	lazy(() => import('src/components/pages/ForgotPassword/ForgotPassword'))
);
const UpdatePassword = Loader(
	lazy(() => import('src/components/pages/UpdatePassword/UpdatePassword'))
);
const CreatePasswordWelcome = Loader(
	lazy(
		() =>
			import('src/components/pages/CreatePasswordWelcome/CreatePasswordWelcome')
	)
);
const CreatePassword = Loader(
	lazy(() => import('src/components/pages/CreatePassword/CreatePassword'))
);
const CreatePasswordSuccess = Loader(
	lazy(
		() =>
			import('src/components/pages/CreatePasswordSuccess/CreatePasswordSuccess')
	)
);

// Status
const SystemStatus = Loader(
	lazy(() => import('src/components/pages/Status/System'))
);
const Status404 = Loader(
	lazy(() => import('src/components/pages/Status/Status404/Status404'))
);
const Status500 = Loader(
	lazy(() => import('src/components/pages/Status/Status500/Status500'))
);
const StatusComingSoon = Loader(
	lazy(() => import('src/components/pages/Status/ComingSoon/ComingSoon'))
);
const StatusMaintenance = Loader(
	lazy(() => import('src/components/pages/Status/Maintenance/Maintenance'))
);
const StatusSuccess = Loader(
	lazy(() => import('src/components/pages/Status/Success/Success'))
);
const StatusFailure = Loader(
	lazy(() => import('src/components/pages/Status/Failure/Failure'))
);
const StatusCancel = Loader(
	lazy(() => import('src/components/pages/Status/Cancel/Cancel'))
);

const routes: RouteObject[] = [
	{
		/**
		 * Basic container element without a Sidebar, top Navbar, or authentication.
		 */
		path: '',
		element: <AuthLayout />,
		children: [
			// BASE
			{
				path: '/',
				element: <Navigate to={pages.auth.login.path} replace />,
			},
			// AUTH
			{
				path: pages.auth.root,
				children: [
					{
						path: pages.auth.login.path,
						element: <Login />,
					},
					{
						path: pages.auth.forgotPassword.path,
						element: <ForgotPassword />,
					},
					{
						path: pages.auth.updatePassword.path,
						element: <UpdatePassword />,
					},
					{
						path: pages.auth.createPasswordWelcome.path,
						element: <CreatePasswordWelcome />,
					},
					{
						path: pages.auth.createPassword.path,
						element: <CreatePassword />,
					},
					{
						path: pages.auth.createPasswordSuccess.path,
						element: <CreatePasswordSuccess />,
					},
					{ path: pages.auth.register.path, element: <Register /> },
				],
			},
			// STATUS
			{
				path: pages.status.root,
				children: [
					{
						path: '',
						element: <Navigate to="404" replace />,
					},
					{
						path: pages.status.systemStatus.path,
						element: <SystemStatus />,
					},
					{
						path: pages.status.status404.path,
						element: <Status404 />,
					},
					{
						path: pages.status.status500.path,
						element: <Status500 />,
					},
					{
						path: pages.status.statusMaintenance.path,
						element: <StatusMaintenance />,
					},
					{
						path: pages.status.statusComingSoon.path,
						element: <StatusComingSoon />,
					},
					{
						path: pages.status.statusSuccess.path,
						element: <StatusSuccess />,
					},
					{
						path: pages.status.statusFailure.path,
						element: <StatusFailure />,
					},
					{
						path: pages.status.statusCancel.path,
						element: <StatusCancel />,
					},
				],
			},
			// NOT FOUND
			{
				path: '*',
				element: <Status404 />,
			},
		],
	},
];

export default routes;
