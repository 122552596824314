import { CircularProgress } from '@mui/material';
import { Icon, IconProps } from '../Icon/Icon';
  import MuiLoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';
  
  interface CustomButtonProps extends LoadingButtonProps {
    label?: string;
    startIcon?: IconProps['icon'];
    endIcon?: IconProps['icon'];
  }
  
  export const LoadingButton = ({ label, ...props }: CustomButtonProps) => {
    return (
      <MuiLoadingButton
        {...props}
        startIcon={props.startIcon && <Icon icon={props.startIcon} />}
        endIcon={props.endIcon && <Icon icon={props.endIcon} />}
        loadingIndicator={<CircularProgress color='primary' size={20} thickness={5} />}
      >
        {props.children || label}
      </MuiLoadingButton>
    );
  };