const pages = {
	auth: {
		root: 'auth',
		login: {
			name: 'login',
			path: '/auth/login',
		},
		createPasswordWelcome: {
			name: 'create-password-welcome',
			path: '/auth/create-password-welcome',
		},
		createPassword: {
			name: 'create-password',
			path: '/auth/create-password',
		},
		createPasswordSuccess: {
			name: 'create-password-success',
			path: '/auth/create-password-success',
		},
		forgotPassword: {
			name: 'forgot-password',
			path: '/auth/forgot-password',
		},
		updatePassword: {
			name: 'update-password',
			path: '/auth/update-password',
		},
		register: {
			name: 'register',
			path: '/auth/register',
		},
	},
	status: {
		root: 'status',
		unverified: {
			name: 'unverified',
			path: '/status/unverified',
		},
		statusComingSoon: {
			name: 'coming-soon',
			path: '/status/coming-soon',
		},
		statusMaintenance: {
			name: 'maintenance',
			path: '/status/maintenance',
		},
		status404: {
			name: '404',
			path: '/status/404',
		},
		status500: {
			name: '500',
			path: '/status/500',
		},
		statusSuccess: {
			name: 'success',
			path: '/status/success',
		},
		statusFailure: {
			name: 'failure',
			path: '/status/failure',
		},
		statusCancel: {
			name: 'cancel',
			path: '/status/cancel',
		},
		systemStatus: {
			name: 'system-status',
			path: '/status/system-status',
		},
	},
};

export default pages;
