import { Box, ListItem, Stack, useTheme } from '@mui/material';
import { Draggable } from 'react-beautiful-dnd';
import { IconButton, Icon } from 'features';
import { UilDraggabledots } from '@iconscout/react-unicons';

export interface DraggableListItemProps {
	id: string;
	index: number;
	content: JSX.Element | string;
	onDeleteClick: (index: number) => void;
}

const DraggableListItem = ({
	id,
	index,
	content,
	onDeleteClick,
}: DraggableListItemProps) => {
	const theme = useTheme();

	return (
		<Draggable key={id} draggableId={id} index={index}>
			{(provided) => (
				<ListItem
					sx={{
						border: `1px solid ${theme.colors.alpha.grey[3]}`,
						borderRadius: '10px',
						width: '100%',
						my: 2,
					}}
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
				>
					<Box width={'10%'} display="flex" alignItems={'center'} draggable>
						<UilDraggabledots
							style={{
								cursor: 'grab',
							}}
						/>
					</Box>
					<Stack direction="row" alignItems={'center'} width={'100%'}>
						<Box width={'90%'} display="flex" alignItems={'center'}>
							{content}
						</Box>
						<Box width={'10%'} display="flex" alignItems={'center'}>
							<IconButton
								icon={'UilTrashAlt'}
								onClick={() => onDeleteClick(index)}
							/>
						</Box>
					</Stack>
				</ListItem>
			)}
		</Draggable>
	);
};

export default DraggableListItem;
