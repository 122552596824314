import { CssBaseline } from "@mui/material";
import { useRoutes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import router from "src/router/router";
import { AuthProvider } from "./features/authentication";
import { ThemeProviderWrapper, initMonitoring } from "features";
import { sentryConfig } from './config/sentry';

initMonitoring(sentryConfig, 'njmzf9t0lk');

function App() {
  const content = useRoutes(router);

  return (
    <AuthProvider>
      <ThemeProviderWrapper>
        <CssBaseline />
        <ToastContainer
          hideProgressBar={true}
          theme={"light"}
          position="bottom-left"
        />
        {content}
      </ThemeProviderWrapper>
    </AuthProvider>
  );
}

export default App;
