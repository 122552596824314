export enum LoginResult {
	Success = 0,
	Fail = 1,
}

export type LoginAttempt = {
	email: string;
	loginResult: LoginResult;
	authToken?: string;
	errorCode?: string;
};

export type LoginAttemptResponse = {
	shouldUpdatePassword: boolean;
	actionCode: string;
};
