import {
	TextField as MuiTextField,
	OutlinedTextFieldProps,
	useTheme,
} from '@mui/material';
export interface CustomTextInputProps extends OutlinedTextFieldProps {
	authInput?: boolean;
};

export const CustomTextInput = ({ ...props }: CustomTextInputProps) => {
	const theme = useTheme();
	return (
		<div>
			<MuiTextField
				sx={{
					input: {
						'&:hover:not(.Mui-disabled)': {
							cursor: 'pointer',
						},
						'&:focus:not(.Mui-disabled)': {
							cursor: 'text',
						},
						borderRadius: '15px',
						...props.authInput && { backgroundColor: theme.colors.alpha.white[100]}
					},
				}}
				InputProps={{
					...props.InputProps,
					notched: false,
				}}
				{...props}
				InputLabelProps={{
					shrink: true,
					sx: {
						...props.authInput && { fontSize: '14px' },
						position: 'relative',
						transform: 'none',
						backgroundColor: 'transparent',
						WebkitTextFillColor: props.authInput ? '#FFFFFF' : '#000000',
						textWrap: 'balance',
					},
					...props.InputLabelProps,
				}}
			></MuiTextField>
		</div>
	);
};

CustomTextInput.defaultProps = {
	variant: 'outlined',
	fullWidth: true,
};
