import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps
} from '@mui/material';
import { Icon, IconProps } from 'features';

interface ButtonProps extends MuiButtonProps {
  label?: string;
  startIcon?: IconProps['icon'];
  endIcon?: IconProps['icon'];
}

export const Button = ({ label, ...props }: ButtonProps) => {
  return (
    <MuiButton
      {...props}
      startIcon={props.startIcon && <Icon icon={props.startIcon} />}
      endIcon={props.endIcon && <Icon icon={props.endIcon} />}
    >
      {props.children || label}
    </MuiButton>
  );
};
