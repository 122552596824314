import {
  Box,
  useTheme,
  styled,
  IconButton,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Backdrop
} from '@mui/material';
import {
  UilMultiply,
  UilTrashAlt,
} from '@iconscout/react-unicons';
import { LoadingButton } from '@mui/lab';
import { Icon, IconProps } from 'features';


const DialogWrapper = styled(Box)(
  ({ theme }) => ({
    backgroundColor: theme.colors.alpha.white[100],
    maxWidth: '336px',
    padding: '32px',
    paddingTop: '24px',
    display: 'flex',
    gap: '12px',
    flexDirection: 'column',
  })
);

const IconBox = styled(Box)(
  ({ theme }) => ({
    backgroundColor: theme.colors.error.main,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    height: '40px',
    margin: '0px',
  })
);

const ActionButton = styled(LoadingButton)(({ theme }) => ({
  backgroundColor: theme.colors.error.main,
  border: '2px solid',
  borderColor: theme.colors.error.main,
  color: theme.colors.alpha.white[100],
  '&.MuiButton-root': {
    '&:hover': {
      backgroundColor: theme.colors.error.dark,
      borderColor: theme.colors.error.dark
    },
  },
  '&.Mui-disabled': {
    borderColor: 'transparent'
  },
}));

const CancelButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.colors.alpha.black[100],
  backgroundColor: theme.colors.alpha.white[100],
  border: '2px solid',
  borderColor: theme.colors.alpha.black[100],
  '&.MuiButton-root': {
    '&:hover': {
      backgroundColor: theme.colors.alpha.black[100],
      color: theme.colors.alpha.white[100]
    }
  }
}));

interface ConfirmationProps {
  title: string;
  bodyText: string;
  buttonText: string;
  icon?: IconProps['icon'];
  modalState: boolean;
  submitLoading?: boolean;
  closeModal: () => void;
  openModal?: () => void;
  modalSubmit?: () => void;
}

export const ConfirmationPopup = ({
  title,
  bodyText,
  buttonText,
  icon,
  modalState,
  submitLoading,
  closeModal,
  modalSubmit,
}: ConfirmationProps) => {
  const theme = useTheme();

  return (
    <Dialog
      open={modalState}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'transparent',
          },
        },
      }}
    >
      <DialogWrapper>
        <DialogTitle
          variant="h6Bold"
          sx={{
            p: 0,
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <IconBox >
              <Icon icon='UilExclamationTriangle' sx={{ color: theme.colors.alpha.white[100], mb: 0.4 }} />
            </IconBox>
            {title}
          </Box>
          <IconButton
            sx={{
              display: 'flex',
              width: 40,
              height: 40,
              backgroundColor: theme.colors.alpha.grey[1],
              borderRadius: '50%'
            }}
            aria-label="close"
            onClick={closeModal}
          >
            <UilMultiply />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <DialogContentText variant="body">{bodyText}</DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: 1.3,
            paddingTop: 1.3,
            '&.MuiDialogActions-root': {
              '&:not': {
                '&(:first-of-type)': {
                  margin: 0
                }
              }
            }
          }}
        >
          <ActionButton
            onClick={modalSubmit ?? closeModal}
            fullWidth
            endIcon={icon ? <Icon icon={icon} /> : <UilTrashAlt />}
            loading={submitLoading}
            size='small'
            variant='contained'
          >
            {buttonText}
          </ActionButton>
          <CancelButton
            onClick={closeModal}
            fullWidth
            loading={submitLoading}
            variant='outlined'
            size='small'
          >
            Cancel
          </CancelButton>
        </DialogActions>
      </DialogWrapper>
    </Dialog>
  );
};

