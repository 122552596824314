import {
	Box,
	useTheme,
	Link,
	InputAdornment,
	Tooltip,
	CardContent,
	CardHeader,
} from '@mui/material';
import {
	CustomTextInput,
	IconButton,
	Icon,
	LoadingButton,
	Checkbox,
} from 'features';
import pages from 'src/router/routes';
import { useNavigate } from 'react-router';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useTogglePasswordVisibility } from 'src/hooks/useTogglePassword';
import { useState } from 'react';
import { useAuth } from 'src/features/authentication/hooks/useAuth';
import {
	AccessToInformationLink,
	PrivacyPolicyLink,
	TermsOfUseLink,
} from 'features/src/constants/documentPaths';
import { toast } from 'react-toastify';

type FormValuesProps = {
	email: string;
	password: string;
	termsAndConditions: boolean;
};

const initialValues: FormValuesProps = {
	email: '',
	password: '',
	termsAndConditions: false,
};

const validationSchema = Yup.object().shape({
	email: Yup.string()
		.max(255)
		.required('Please enter your e-mail address')
		.email('Please enter a valid email address'),
	password: Yup.string().max(255).required('Please enter a password'),
	termsAndConditions: Yup.bool().oneOf([true], 'This field must be checked'),
});

const Login = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);

	const { loginWithEmailAndPassword } = useAuth();

	const { passwordVisibility, eyeIcon, handlePasswordVisibility, tooltip } =
		useTogglePasswordVisibility();

	async function HandleLogin(values: FormValuesProps): Promise<void> {
		if (!values.termsAndConditions) {
			toast.error('Terms and Conditions box is not checked');
		} else {
			setIsLoading(true);
			await loginWithEmailAndPassword(values.email, values.password);
			setIsLoading(false);
		}
	}

	const forgotPassword = () => navigate(pages.auth.forgotPassword.path);

	return (
		<>
			<CardHeader
				sx={{ padding: 0, pb: 2, ml: 1.5 }}
				title="Welcome back!"
				titleTypographyProps={{
					variant: 'h5Bold',
					color: theme.colors.alpha.white[100],
				}}
			/>
			<CardContent sx={{ padding: 0, ml: 1.5 }}>
				<Formik
					validateOnMount
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={(values) => {
						HandleLogin(values);
					}}
				>
					{({ errors, touched, values }) => {
						return (
							<Form>
								<Box
									display={'flex'}
									sx={{
										gap: 2,
										flexDirection: 'column',
									}}
								>
									<Field
										name="email"
										as={CustomTextInput}
										type="text"
										label="Email Address"
										placeholder="Enter your email address"
										error={touched.email && errors.email}
										authInput
										size="small"
										helperText={
											touched.email && errors.email ? (
												<Box alignItems={'center'} display={'flex'} gap={1}>
													<Icon icon={'UilExclamationCircle'} />
													{errors.email}
												</Box>
											) : null
										}
									/>
									<Field
										name="password"
										as={CustomTextInput}
										type={passwordVisibility ? 'text' : 'password'}
										label="Enter Password"
										placeholder="Enter your password"
										error={touched.password && errors.password}
										authInput
										size="small"
										helperText={
											touched.password && errors.password ? (
												<Box alignItems={'center'} display={'flex'} gap={1}>
													<Icon icon={'UilExclamationCircle'} />
													{errors.password}
												</Box>
											) : null
										}
										InputProps={{
											notched: false,
											endAdornment: (
												<InputAdornment position="end">
													<Tooltip title={tooltip}>
														<IconButton
															aria-label="toggle password visibility"
															onClick={handlePasswordVisibility}
															onMouseDown={handlePasswordVisibility}
															icon={eyeIcon}
															tabIndex={-1}
															sx={{
																color: theme.colors.alpha.grey[2],
															}}
														/>
													</Tooltip>
												</InputAdornment>
											),
										}}
									/>
									<Box
										sx={{ display: 'flex', justifyContent: 'space-between' }}
									>
										<Link
											underline="always"
											variant="link"
											style={{
												cursor: 'pointer',
												color: theme.colors.alpha.white[100],
												textDecorationColor: theme.colors.alpha.white[100],
												fontSize: '14px',
											}}
											onClick={forgotPassword}
										>
											Forgot Password?
										</Link>
										<Link
											underline="always"
											variant="link"
											style={{
												cursor: 'pointer',
												color: theme.colors.alpha.white[100],
												textDecorationColor: theme.colors.alpha.white[100],
												fontSize: '14px',
											}}
											href={pages.auth.register.path}
										>
											Register
										</Link>
									</Box>
									<Field
										name="termsAndConditions"
										as={Checkbox}
										sx={{
											'& .MuiTypography-root': {
												color:
													touched.termsAndConditions &&
													errors.termsAndConditions
														? 'red'
														: theme.colors.alpha.white[100],
												textDecorationColor: theme.colors.alpha.white[100],
												fontSize: '12px',
											},
											'& .MuiButtonBase-root': {
												'&.MuiCheckbox-root': {
													color: theme.colors.alpha.white[100],
												},
											},
										}}
										checked={values.termsAndConditions}
										label={
											<>
												{'By using our site, you agree to the '}
												<Link
													underline="always"
													href={TermsOfUseLink}
													target="_blank"
												>
													Terms of Service/Use
												</Link>
												{', '}
												<Link
													underline="always"
													href={PrivacyPolicyLink}
													target="_blank"
												>
													Privacy Policy
												</Link>
												{', and '}
												<Link
													underline="always"
													href={AccessToInformationLink}
													target="_blank"
												>
													Access to Information
												</Link>
											</>
										}
									/>
								</Box>
								<Box
									sx={{
										display: 'flex',
										gap: '20px',
										flexDirection: 'column',
										pt: 2,
									}}
								>
									<LoadingButton
										variant="contained"
										color="primary"
										type="submit"
										disabled={
											!!(touched.email && errors.email) ||
											!!(touched.password && errors.password) ||
											!!(
												touched.termsAndConditions && errors.termsAndConditions
											)
										}
										loading={isLoading}
										sx={{ height: '40px' }}
									>
										Log in
									</LoadingButton>
								</Box>
							</Form>
						);
					}}
				</Formik>
			</CardContent>
		</>
	);
};

export default Login;
