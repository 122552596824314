import { createApi } from '@reduxjs/toolkit/dist/query/react/index';
import { baseQueryWithTokenExpirationCheck } from './config/query.config';
import { TAG_TYPES } from './config/apiTags';
import { LoginAttempt, LoginAttemptResponse } from './types/auth.types';

export const baseApi = createApi({
	reducerPath: 'baseApi',
	baseQuery: baseQueryWithTokenExpirationCheck,
	tagTypes: [...TAG_TYPES],
	endpoints: (builder) => ({
		logLoginAttempt: builder.mutation<LoginAttemptResponse, LoginAttempt>({
			query: (body) => {
				return {
					url: 'Authentication/UserLogin',
					body,
					method: 'POST',
					headers: { authorization: `Bearer ${body.authToken}` },
				};
			},
		}),

		resetUserPassword: builder.mutation<
			{ data: boolean; message?: string },
			{ password: string; userEmail: string; oobCode: string }
		>({
			query: (body) => {
				return {
					url: 'Authentication/ResetUserPassword',
					method: 'PATCH',
					body,
				};
			},
		}),
	}),
});

export const { useLogLoginAttemptMutation, useResetUserPasswordMutation } =
	baseApi;
