import { Outlet } from 'react-router-dom';
import { Card, Grid, useMediaQuery, useTheme } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

export const AuthLayout = () => {
	const carouselHeight = window.innerHeight * 0.9

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

	return (
		<Grid
			container
			columnSpacing={10}
			alignItems='center'
			justifyContent='center'
			sx={{
				height: '130%',
				backgroundImage: 'url(/static/auth-background.jpg)',
				backgroundSize: { xs: 'auto 100%', lg: '100% auto' },
				py: 2
			}}
		>
			<Grid item xs={11} lg={6}>
				<Grid container justifyContent='flex-end' alignItems='center' height='100%'>
					<Grid item xs={12} lg={7}>
						<Grid container
							direction='column'
							justifyContent='center'
							alignItems='center'
							spacing={isMobile ? 5 : 3}
						>
							<Grid item>
								<img
									src={'/static/intelekt-ag-white.svg'}
									alt="Intelekt Ag logo"
									width='100%'
								/>
							</Grid>
							<Grid item>
								<Card
									elevation={0}
									square
									sx={{
										backgroundColor: 'transparent',
										borderRadius: 0,
										maxWidth: '280px'
									}}
								>
									<Outlet />
								</Card>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={0} lg={6} height={'100%'} sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' } }}>
				<Grid container height='100%' width='100%' justifyContent='flex-start' alignItems='center'>
					<Grid item xs={12} pl={3}>
						<Carousel
							autoPlay
							cycleNavigation
							interval={5000}
							indicators={false}
							navButtonsAlwaysInvisible
						>
							<img src="/static/auth-image-1.svg" alt="" height={carouselHeight} />
							<img src="/static/auth-image-2.svg" alt="" height={carouselHeight} />
							<img src="/static/auth-image-3.svg" alt="" height={carouselHeight} />
						</Carousel>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} lg={0} sx={{ display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' } }}>
				<Grid container alignItems='center' justifyContent='center'>
					<Grid item pt={3}>
						<img
							src={'/static/powered-by-ig-white.svg'}
							alt="Powered by Inteligro logo"
							width='200px'
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
