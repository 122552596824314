import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popper from '@mui/material/Popper';
import { useTheme, styled, ComponentsProps } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { CircularProgress, FormControl, MenuItem } from '@mui/material';
import {
	CommonProps,
	DefaultComponentProps,
	OverridableTypeMap,
} from '@mui/material/OverridableComponent';

const LISTBOX_PADDING = 8; // px

const renderRow = (props: ListChildComponentProps) => {
	const { data, style, index } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: (style.top as number) + LISTBOX_PADDING,
	};

	return (
		<MenuItem
			{...dataSet[0]}
			noWrap
			style={inlineStyle}
			value={dataSet[1]['id']}
		>
			{dataSet[1]['name'] || dataSet[1]['description']}
		</MenuItem>
	);
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data: any) => {
	const ref = React.useRef<VariableSizeList>(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
};

// Adapter for react-window
const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
	const { children, ...other } = props;
	const itemData: React.ReactElement[] = [];
	(children as React.ReactElement[]).forEach(
		(item: React.ReactElement & { children?: React.ReactElement[] }) => {
			itemData.push(item);
			itemData.push(...(item.children || []));
		}
	);

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true,
	});
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child: React.ReactElement) => {
		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width="100%"
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType="ul"
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

const StyledPopper = styled(Popper)({
	[`& .${autocompleteClasses.listbox}`]: {
		boxSizing: 'border-box',
		'& ul': {
			padding: 0,
			margin: 0,
		},
	},
});

export const VirtualizedAutoComplete = <T,>({
	label,
	options,
	value,
	onChange,
	disabled,
	getOptionLabel,
	onClick,
	width,
	labelComponent: LabelComponent,
	size,
	labelProps,
	placeholder,
	loading,
}: {
	label?: string;
	value?: T;
	options: T[];
	onChange: (
		event: React.SyntheticEvent<Element, Event>,
		value: T | null
	) => void;
	disabled: boolean;
	loading?: boolean;
	getOptionLabel: (option: T) => string;
	onClick?: () => void;
	width?: number | string | undefined;
	labelComponent?: React.ComponentType<{
		id?: string;
		children: string;
	}>;
	labelProps?: DefaultComponentProps<OverridableTypeMap>;
	placeholder?: string;
	size?: 'small' | 'medium';
}) => {
	return (
		<FormControl fullWidth>
			{label && LabelComponent && (
				<LabelComponent id="autocomplete-label" {...labelProps}>
					{label}
				</LabelComponent>
			)}
			<Autocomplete
				id="virtualize-autocomplete"
				sx={{ width: width ?? '100%' }}
				disableListWrap
				options={options}
				value={value}
				onChange={onChange}
				disabled={disabled}
				PopperComponent={StyledPopper}
				ListboxComponent={ListboxComponent}
				aria-labelledby="autocomplete-label"
				renderInput={(params) => (
					<TextField
						{...params}
						label={!LabelComponent ? label : ''}
						placeholder={placeholder}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<React.Fragment>
									{loading ? (
										<CircularProgress color="inherit" size={20} />
									) : null}
									{params.InputProps.endAdornment}
								</React.Fragment>
							),
						}}
					/>
				)}
				renderOption={(props, option, state) =>
					[props, option, state.index] as React.ReactNode
				}
				getOptionLabel={getOptionLabel}
				onClick={onClick}
				onOpen={onClick}
				fullWidth
				size={size ?? 'medium'}
				loading={loading ?? false}
			/>
		</FormControl>
	);
};
