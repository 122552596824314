import { memo } from 'react';
import { DraggableListProps } from '.';
import DraggableListItem from './DraggableListItem';

const genericMemo: <T>(component: T) => T = memo;

interface ItemListProps<T>
	extends Omit<DraggableListProps<T>, 'onAddItem' | 'onItemDrop'> {}

const ItemList = <T,>({
	data,
	getItemId,
	getListItemContent,
	onDeleteClick,
}: ItemListProps<T>) => {
	return data.map((item, index) => (
		<DraggableListItem
			key={`list-item-${index}`}
			id={getItemId(item)}
			content={getListItemContent(item)}
			index={index}
			onDeleteClick={onDeleteClick}
		/>
	));
};

export default genericMemo(ItemList);
